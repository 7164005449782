import React, { useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import { Formik, Form, Field } from "formik";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import { LoadingContext } from "../../contexts/loading";
import { StepsContext } from "../../contexts/steps";
import { StateContext } from "../../contexts/state";
import { FormContext } from "../../contexts/form";
import { ScrollContext } from "../../contexts/scroll";

import { putStep } from "../../api/client";
import { postStep } from "../../api/client";

import { getColClass } from "../../utilities";

import InputElement from "./InputElement";
import TextElement from "./TextElement";
import CheckElement from "./CheckElement";
import RadioElement from "./RadioElement";
import SelectElement from "./SelectElement";
import TextareaElement from "./TextareaElement";
import ImageElement from "./ImageElement";
import DateElement from "./DateElement";
import LocationsObj from "./LocationsObj";
import ServicesObj from "./ServicesObj";

function FormElement({ children }) {
  const navigate = useNavigate();

  const [, setLoadingCon] = useContext(LoadingContext);
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [stateCon, setStateCon] = useContext(StateContext);
  const [formCon, setFormCon] = useContext(FormContext);
  const [, setScrollCon] = useContext(ScrollContext);

  // const onKeyDown = (keyEvent) => {
  //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
  //     keyEvent.preventDefault();
  //   }
  // };

  const [t, setT] = useState(null);
  const localValues = [];

  const getLocalValues = () => {
    let result = _.compact(localValues);
    result = _.uniq(result);
    return result;
  };

  const handleNextStep = () => {
    setScrollCon("top");
    setLoadingCon({ "bool": true, "show": false });

    if (stepsCon?.stepData?.next === "pdf") {
      navigate("/" + stepsCon?.stepData?.next, { replace: true });
    } else {
      navigate("/" + stepsCon?.stepData?.next);
    }
  };

  const setFormUpdate = (bool) => {
    let newForm = { ...formCon };
    newForm["update"] = bool;
    setFormCon(newForm);
  };

  const updateStep = async (id, valObject) => {
    let result = await putStep(id, valObject)
      .then((response) => {
        if (response.data.steps !== undefined) {
          let index = _.findIndex(stepsCon?.steps, function (o) {
            return o.identifier === id;
          });
          let newState = { ...stepsCon };
          let newSteps = [...stepsCon?.steps];
          newSteps[index].renderables = response.data.steps.renderables;
          newState["steps"] = newSteps;
          newState["stepData"] = newSteps[index];
          setStepsCon(newState);
        }
        return response.data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
    return result;
  };

  const handleChange = async ({
    e,
    values,
    errors,
    setErrors,
    setFieldValue,
    setFieldError,
  }) => {
    let locValues = getLocalValues();

    let val = {};

    if (_.isArray(values[e.target.name])) {
      let index = _.indexOf(values[e.target.name], e.target.value);
      let newArray = values[e.target.name];
      if (index > -1) {
        _.remove(newArray, (n) => n === e.target.value);
      } else {
        newArray.push(e.target.value);
      }
      val[e.target.name] = newArray;
    } else {
      if (e.target.type === "checkbox") {
        val[e.target.name] = [e.target.value];
      } else {
        val[e.target.name] = e.target.value;
      }
    }

    let result = await updateStep(stepsCon?.stepData.identifier, val);

    if (result.values) {
      // check if values got deleted
      locValues.forEach((key) => {
        if (result.values !== undefined && !result.values.hasOwnProperty(key)) {
          if (_.isArray(values[key])) {
            setFieldValue(key, []);
          }
          setFieldValue(key, "");
        } else {
          setFieldValue(key, result.values[key]);
        }
      });

      let newForm = { ...formCon };
      newForm["values"] = result.values;
      setFormCon(newForm);
    }

    // add possible errors
    if (result.errors && result.errors.length !== 0) {
      setFieldError(e.target.name, result.errors[e.target.name]);
    } else {
      let newErrors = _.omit(errors, [e.target.name]);
      setErrors(newErrors);
    }

    // signal end of update
    setFormUpdate(false);
  };

  if (stepsCon?.stepData.renderables === undefined) return null;
  if (stepsCon?.stepData.renderables.length === 0) return null;

  return (
    <Formik
      initialValues={formCon?.values}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setErrors }) => {
        setLoadingCon({ "bool": true, "show": false });

        let locValues = getLocalValues();
        let newValues = {};
        locValues.forEach((item) => {
          if (values[item]) {
            newValues[item] = values[item];
          } else {
            newValues[item] = "";
          }
        });

        // add values for untouched elements
        // let list = cleanList(stepsCon?.stepData.renderables);
        // list.forEach((item) => {
        //   if (!newValues.hasOwnProperty(item.identifier)) {
        //     values[item.identifier] = "";
        //   }
        // });

        postStep(stepsCon?.stepData.identifier, newValues)
          .then((response) => {
            if (!_.isEmpty(response.data.errors)) {
              setErrors(response.data.errors);

              // add notifications for other errors
              let arr = _.keys(newValues);
              let otherErrors = _.omit(response.data.errors, arr);
              if (!_.isEmpty(otherErrors)) {
                let newState = { ...stateCon };
                newState["notifications"] = otherErrors;
                setStateCon(newState);
              }

              setLoadingCon({ "bool": false, "show": false });
            } else {
              handleNextStep();
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            setLoadingCon({ "bool": false, "show": false });
          });
      }}
    >
      {({ values, errors, setErrors, setFieldValue, setFieldError }) => (
        <Form
          noValidate
          autoComplete="off"
          onChange={async (e) => {
            if (stepsCon?.stepData?.validation === "change") {
              setFormUpdate(true);
              setFieldValue([e.target.name], e.target.value);

              let params = {
                e,
                values,
                errors,
                setErrors,
                setFieldValue,
                setFieldError,
              };
              if (
                e.target.type === "text" ||
                e.target.type === "number" ||
                e.target.type === "password"
              ) {
                if (t) clearTimeout(t);
                setT(
                  setTimeout(async () => {
                    handleChange({ ...params });
                  }, 500)
                );
              } else if (e.target.type === "textarea") {
                if (t) clearTimeout(t);
                setT(
                  setTimeout(async () => {
                    handleChange({ ...params });
                  }, 750)
                );
              } else {
                handleChange({ ...params });
              }
            }
          }}
        >
          {stepsCon?.stepData.renderables.map((element, rowIndex) => {
            localValues.push(element.identifier);
            // no grid
            if (!element.renderables) {
              let classValue = "col";
              rowIndex === 0 ? (classValue += " first") : (classValue += "");

              let options = {
                field: {},
                form: {},
                meta: {},
                item: element,
                name: element.identifier,
                values: values,
                updateStep: updateStep,
                stepID: stepsCon?.stepData.identifier,
                index: [rowIndex],
              };

              if (element.type === "checkbox" || element.type === "radio") {
                return (
                  <div className="row" key={rowIndex}>
                    <div className={classValue}>
                      <div
                        id={element.identifier}
                        role="group"
                        className={element.type + "-element"}
                      >
                        {!element.hideLabel && (
                          <div className="title">{element.label}</div>
                        )}
                        <div className="row">
                          {element.options &&
                            element.options.map((option, i) => {
                              options.option = option;
                              if (element.type === "checkbox") {
                                return (
                                  <CheckElement {...options} key={i}>
                                    <Field
                                      type="checkbox"
                                      name={element.identifier}
                                      value={option[element.optionValueField]}
                                    />
                                  </CheckElement>
                                );
                              } else {
                                return (
                                  <RadioElement {...options} key={i}>
                                    <Field
                                      type="radio"
                                      name={element.identifier}
                                      value={option[element.optionValueField]}
                                    />
                                  </RadioElement>
                                );
                              }
                            })}
                        </div>
                        {errors[element.identifier] && (
                          <div className="row">
                            <div className="col">
                              <div className="alert alert-danger">
                                {errors[element.identifier]}
                              </div>{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div className="row" key={rowIndex}>
                  <div className={classValue}>
                    <Field name={element.identifier}>
                      {({ field, form, meta }) => {
                        options.field = field;
                        options.form = form;
                        options.meta = meta;
                        if (element.type === "locationsObj")
                          return (
                            <LocationsObj
                              setFieldValue={setFieldValue}
                              {...options}
                            />
                          );
                        if (element.type === "servicesObj")
                          return (
                            <ServicesObj
                              setFieldValue={setFieldValue}
                              {...options}
                            />
                          );
                        if (element.type === "image")
                          return <ImageElement {...options} />;
                        if (element.type === "date")
                          return (
                            <DateElement
                              setFieldValue={setFieldValue}
                              {...options}
                            />
                          );
                        if (element.type === "input")
                          return <InputElement {...options} />;
                        if (element.type === "select")
                          return (
                            <SelectElement
                              setFieldValue={setFieldValue}
                              {...options}
                            />
                          );
                        if (element.type === "text")
                          return <TextElement {...options} />;
                        if (element.type === "textarea")
                          return (
                            <TextareaElement
                              setFieldValue={setFieldValue}
                              {...options}
                            />
                          );
                      }}
                    </Field>
                  </div>
                </div>
              );
            }

            // with grid
            return (
              <div className="row" key={rowIndex}>
                {element.renderables.map((column, columnIndex) => {
                  let classValue = "col-12";
                  rowIndex === 0
                    ? (classValue += " first")
                    : (classValue += "");
                  classValue = getColClass(classValue, column.viewPorts);

                  return (
                    <div className={classValue} key={columnIndex}>
                      {column.renderables.map((item, colIndex) => {
                        localValues.push(item.identifier);
                        let options = {
                          field: {},
                          form: {},
                          meta: {},
                          item: item,
                          name: item.identifier,
                          values: values,
                          updateStep: updateStep,
                          stepID: stepsCon?.stepData?.identifier,
                          index: [rowIndex, colIndex],
                        };

                        if (item.type === "checkbox" || item.type === "radio") {
                          return (
                            <React.Fragment key={colIndex}>
                              <div
                                id={item.identifier}
                                role="group"
                                className={item.type + "-element"}
                              >
                                {!item.hideLabel && (
                                  <div className="title">{item.label}</div>
                                )}
                                <div className="row">
                                  {item.options &&
                                    item.options.map((option, i) => {
                                      options.option = option;
                                      if (item.type === "checkbox") {
                                        return (
                                          <CheckElement {...options} key={i}>
                                            <Field
                                              type="checkbox"
                                              name={item.identifier}
                                              value={
                                                option[item.optionValueField]
                                              }
                                            />
                                          </CheckElement>
                                        );
                                      } else {
                                        return (
                                          <RadioElement {...options} key={i}>
                                            <Field
                                              type="radio"
                                              name={item.identifier}
                                              value={
                                                option[item.optionValueField]
                                              }
                                            />
                                          </RadioElement>
                                        );
                                      }
                                    })}
                                </div>
                                {errors[item.identifier] && (
                                  <div className="row">
                                    <div className="col">
                                      <div className="alert alert-danger">
                                        {errors[item.identifier]}
                                      </div>{" "}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        }

                        return (
                          <React.Fragment key={colIndex}>
                            <Field name={item.identifier}>
                              {({ field, form, meta }) => {
                                options.field = field;
                                options.form = form;
                                options.meta = meta;
                                if (item.type === "locationsObj")
                                  return (
                                    <LocationsObj
                                      setFieldValue={setFieldValue}
                                      {...options}
                                    />
                                  );
                                if (item.type === "servicesObj")
                                  return (
                                    <ServicesObj
                                      setFieldValue={setFieldValue}
                                      {...options}
                                    />
                                  );
                                if (item.type === "image")
                                  return <ImageElement {...options} />;
                                if (item.type === "date")
                                  return (
                                    <DateElement
                                      setFieldValue={setFieldValue}
                                      {...options}
                                    />
                                  );
                                if (item.type === "input")
                                  return <InputElement {...options} />;
                                if (item.type === "select")
                                  return (
                                    <SelectElement
                                      setFieldValue={setFieldValue}
                                      {...options}
                                    />
                                  );
                                if (item.type === "text")
                                  return <TextElement {...options} />;
                                if (item.type === "textarea")
                                  return (
                                    <TextareaElement
                                      setFieldValue={setFieldValue}
                                      {...options}
                                    />
                                  );
                              }}
                            </Field>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {children}
        </Form>
      )}
    </Formik>
  );
}

export default FormElement;
