import React, { useEffect, useState, useContext, useRef } from "react";
import _ from "lodash";
import { useLocation, Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";

import "./App.scss";
import { loadSteps, loadPublicFolderURL } from "./api/client";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import ActivityIndicator from "./components/ActivityIndicator";
import Notification from "./components/Notification";
import Sidebar from "./components/Sidebar";
import { useWindowSize } from "./utilities";

import { StateContext } from "./contexts/state";
import { LoadingContext } from "./contexts/loading";
import { StepsContext } from "./contexts/steps";
import { ScrollContext } from "./contexts/scroll";

const footerCode = (
  <React.Fragment>
    <div className="col">
      <p>© Evangelische Heimstiftung GmbH</p>
    </div>
    <div className="col-auto">
      <a
        href="https://www.ev-heimstiftung.de/impressum/"
        target="_blank"
        rel="noreferrer"
      >
        Impressum
      </a>
    </div>
    <div className="col-auto">
      <a
        href="https://www.ev-heimstiftung.de/datenschutz"
        target="_blank"
        rel="noreferrer"
      >
        Datenschutz
      </a>
    </div>
  </React.Fragment>
);

function App() {
  const [stateCon, setStateCon] = useContext(StateContext);
  const [loadingCon, setLoadingCon] = useContext(LoadingContext);
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [scrollCon, setScrollCon] = useContext(ScrollContext);

  const [mainStyle, setMainStyle] = useState({});

  const location = useLocation();
  const size = useWindowSize();

  const mainRef = useRef();
  const appRef = useRef();

  const updateHeight = () => {
    let height = document.getElementById("topBar").clientHeight;
    let newMainStyle = {
      paddingTop: height + "px",
    };
    setMainStyle(newMainStyle);
  };

  const clearNotifications = () => {
    let newState = { ...stateCon };
    newState["notifications"] = [];
    setStateCon(newState);
  };

  const setErrorNotification = (text) => {
    let newState = { ...stateCon };
    newState["notifications"] = [text];
    setStateCon(newState);
  };

  const processResponse = (response) => {
    if (response.data.errors.length > 0) {
      setErrorNotification(response.data.errors);
    } else {
      let combiSteps = [
        { identifier: "start", label: "Start" },
        ...response?.data?.steps,
      ];
      let newSteps = _.map(combiSteps, (step, index) => {
        let obj = {};
        obj["identifier"] = step.identifier;
        obj["label"] = step.label;
        obj["renderables"] = [];
        obj["validation"] = "change";
        obj["api"] = {};
        if (combiSteps[index - 1] !== undefined) {
          obj["prev"] = combiSteps[index - 1].identifier;
        }
        if (combiSteps[index + 1] !== undefined) {
          obj["next"] = combiSteps[index + 1].identifier;
        }
        return obj;
      });
      let newStepsCon = { ...stepsCon };
      newStepsCon["steps"] = newSteps;
      setStepsCon(newStepsCon);
    }
  };

  const initSteps = () => {
    setLoadingCon({ "bool": true, "show": true });
    clearNotifications();

    loadPublicFolderURL();
    loadSteps()
      .then((response) => {
        response.status === 200
          ? processResponse(response)
          : setErrorNotification(
              "Hoppla, etwas ist schief gelaufen. Bitte versuchen Sie es erneut."
            );
      })
      .catch((err) => {
        Sentry.captureException(err);
        setErrorNotification(
          "Hoppla, etwas ist schief gelaufen. Bitte versuchen Sie es erneut."
        );
      })
      .then(() => {
        setTimeout(() => {
          updateHeight();
          setLoadingCon({ "bool": false, "show": false });
        }, 1000);
      });
  };

  useEffect(() => {
    if (scrollCon === "top") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      appRef.current.scrollTo(0, 0);
      mainRef.current.scrollTo(0, 0);
      setTimeout(function () {
        setScrollCon("");
      }, 500);
    } else if (scrollCon === "contactFormHook") {
      let id = document.getElementById(scrollCon);
      if (id) {
        id.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setTimeout(function () {
        setScrollCon("");
      }, 500);
    } else if (scrollCon !== "") {
      setTimeout(function () {
        let id = document.getElementById(scrollCon);
        if (id) {
          id.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        setTimeout(function () {
          setScrollCon("");
        }, 500);
      }, 500);
    }
    // eslint-disable-next-line
  }, [scrollCon]);

  useEffect(() => {
    updateHeight();
    // eslint-disable-next-line
  }, [size]);

  useEffect(() => {
    setScrollCon("top");
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    initSteps();
    // eslint-disable-next-line
  }, [stateCon?.status === true]);

  useEffect(() => {
    initSteps();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app" ref={appRef}>
      <ActivityIndicator />
      <div
        className={loadingCon.bool && loadingCon.show ? "home loading" : "home"}
      >
        <header id="topBar">
          <Header />
          <ProgressBar />
          <Notification />
        </header>
        <main id="main" style={mainStyle} ref={mainRef}>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col">
                <Outlet />
                <div className="footer row mt-5 py-3 d-none d-sm-flex">
                  {footerCode}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <Sidebar />
              </div>
            </div>
            <div className="footer row mt-5 py-3 d-sm-none">{footerCode}</div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
