import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "bootstrap/dist/css/bootstrap.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import StartView from "./components/views/StartView";
import FormView from "./components/views/FormView";
import ResultView from "./components/views/ResultView";
import PdfView from "./components/views/PdfView";
import BaseView from "./components/views/BaseView";

import { StateProvider } from "./contexts/state";
import { LoadingProvider } from "./contexts/loading";
import { StepsProvider } from "./contexts/steps";
import { FormProvider } from "./contexts/form";
import { LocationsProvider } from "./contexts/locations";
import { ServicesProvider } from "./contexts/services";
import { ScrollProvider } from "./contexts/scroll";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: "https://f2f650108da748f8a189060d293e93b8@o990541.ingest.sentry.io/6345894",
  enabled: true,
  debug: false,
  integrations: [new BrowserTracing()],
  release: "ehs-bedarfsplaner@" + process.env.npm_package_version,
  sampleRate: 0.1,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <StateProvider>
        <StepsProvider>
          <FormProvider>
            <LocationsProvider>
              <ServicesProvider>
                <ScrollProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<App />}>
                        <Route index element={<BaseView />} />
                        <Route path="start" element={<StartView />} />
                        <Route path="result" element={<ResultView />} />
                        <Route path="pdf" element={<PdfView />} />
                        <Route path="admin" />
                        <Route path="*" element={<FormView />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </ScrollProvider>
              </ServicesProvider>
            </LocationsProvider>
          </FormProvider>
        </StepsProvider>
      </StateProvider>
    </LoadingProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
